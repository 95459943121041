import { isACHExpired } from "../../utils"

export default {
	loading: false,
	fetchError: false,
	list: [],
	current: null,
	propertiesAreSaving: false,
	dispatchDetailsByContract: null,
	dispatchDetailsByContractError: false,
	dispatchDetailsByContractLoading: false,
	dispatchDetailsByContractLoadingPromise: null,

	offsetDispatchDetailsByContract: null,
	offsetDispatchDetailsByContractError: false,
	offsetDispatchDetailsByContractLoading: false,
	offsetDispatchDetailsByContractLoadingPromise: null,

	expirationInfoLoading: false,
	expirationInfoLoadingPromise: null,

	activePageDispatches: [],

	serviceItems: [],
	serviceItemsLoading: false,
	serviceItemsError: null,
	serviceItemCategories: null,
	limitationsAndExclusions: null,
	serviceItemsLoadingPromise: null,
	isDownloadingContract: false,

	hasMultipleContracts() {
		return this.list.length > 1
	},

	isFirstYearCustomerInWaitingPeriod() {
		return Boolean(this.current?.salesChannel === "DC" && this.current?.status === "L")
	},

	getContractNotifications({ expirationInfo, paymentInfo, ...contract }) {
		const notifications = []

		if (contract.status !== "A" && contract.status !== "L") {
			notifications.push("EXPIRATION_ALERTS.CONTRACT_IS_NOT_ACTIVE")
		}

		if (expirationInfo) {
			if (expirationInfo.PastClosingDate) {
				notifications.push("EXPIRATION_ALERTS.LISTING_IS_PAST_CLOSING_DATE")
			}

			if (!expirationInfo.AlreadyRenewed && !expirationInfo.AutoPay && (expirationInfo.IsExpired || expirationInfo.IsExpiring)) {
				if (expirationInfo.ContractStatus === "A") {
					notifications.push(
						expirationInfo.IsExpired ? "EXPIRATION_ALERTS.CONTRACT_IS_EXPIRED" : "EXPIRATION_ALERTS.CONTRACT_IS_EXPIRING"
					)
				} else {
					notifications.push(
						expirationInfo.IsExpired ? "EXPIRATION_ALERTS.LISTING_IS_EXPIRED" : "EXPIRATION_ALERTS.LISTING_IS_EXPIRING"
					)
				}
			}
		}

		if (paymentInfo?.pastDueAmounts) {
			if (paymentInfo.pastDueAmounts.premium > 0) {
				notifications.push("PAST_DUE_ALERTS.PREMIUM_NOTIFICATION_MESSAGE")
			}

			if (paymentInfo.pastDueAmounts.serviceFee > 0) {
				notifications.push("PAST_DUE_ALERTS.SERVICE_FEE_NOTIFICATION_MESSAGE")
			}
		}

		if (paymentInfo?.methods?.[0]) {
			const paymentMethod = paymentInfo.methods[0]
			if (isACHExpired(paymentMethod)) {
				notifications.push("PAYMENT_METHOD_ALERTS.ACH_EXPIRED_NOTIFICATION")
			}
		}

		return notifications
	},

	getFullStreetAddress({ property }) {
		if (property.streetAddress2 !== "") {
			return `${property.streetAddress} ${property.streetAddress2}`
		}
		return property.streetAddress
	},
}
