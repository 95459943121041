import GenericService from "./generic"
import { capitalizeWords } from "../utils"

const SERVICE_NAME = "contract"

class ContractService extends GenericService {
	constructor() {
		super(SERVICE_NAME)

		this.useAccessTokenInAxios()
		this.useCheckValidateContractAccessErrorInResponse()
	}

	getMultipleContractInformation(contractIDs) {
		return Promise.all(
			contractIDs.map((contractID) =>
				this.axios
					.get(`/my-account/contract/properties`, {
						params: {
							contract_id: contractID,
						},
					})
					.then((response) => {
						const contract = response.contractsProperty[0]
						contract.property.streetAddress = capitalizeWords(contract.property.streetAddress)
						contract.property.streetAddress2 = capitalizeWords(contract.property.streetAddress2)
						contract.property.city = capitalizeWords(contract.property.city)
						return contract
					})
			)
		)
	}

	removeContract(contractID) {
		return this.axios.delete("/my-account/properties/" + contractID)
	}

	async lookupContract(searchFields) {
		const response = await this.axios.post("/my-account/contract/search", searchFields)

		if (response.contracts?.length) {
			response.contracts = response.contracts.map((contract) => {
				contract.property.streetAddress = capitalizeWords(contract.property.streetAddress)
				contract.property.streetAddress2 = capitalizeWords(contract.property.streetAddress2)
				contract.property.city = capitalizeWords(contract.property.city)
				return contract
			})
		}

		return response
	}

	addProperties(contracts) {
		return this.axios.post("/my-account/properties", contracts)
	}

	addPropertiesConfirm(token) {
		return this.axios.post("/my-account/properties/token", { token })
	}

	getContractDocument(contractID) {
		return this.axios.get(`/my-account/contract/${contractID}/document`)
	}

	getExpirationInfo(contractID) {
		return this.axios.get(`/my-account-renewals/${contractID}/expiration-alert`)
	}

	getUpgradeInfo(contractID) {
		return this.axios.get(`/my-account-renewals/${contractID}/upgrade-banner-info`)
	}

	async checkPilotGroup(contractID, pilotGroupType, sessionUUID = undefined) {
		const response = await this.axios.get(`/my-account/contract/${contractID}/pilot-group/${pilotGroupType}`, {
			params: {
				session_id: sessionUUID,
			},
		})

		return response.isInPilotGroup
	}

	getCancelElegibility(contractID) {
		return this.axios.get(`/my-account/contract/${contractID}/cancel/eligibility`)
	}

	async setAutoRenew(contractID, isAutoRenewal) {
		return this.axios.post(`my-account/contract/${contractID}/set-auto-renew`, { autoRenew: isAutoRenewal })
	}
}

export default new ContractService()
