import React, { useEffect, useState } from "react"
import { GlobalHeader, useAppContext, Ahs2024LogomarkColor, HsaLogoColor, Ahs2024LogoColor } from "@ftdr/blueprint-components-react"
import { UserActions } from "../../store/user/actions"
import { getLoginRedirect, getUrlByType, isDesktop, isSiteAHS, setLoginRedirect } from "../../utils"
import { ExternalLinkType, HeaderID } from "../../services/homebase"
import { connect } from "react-redux"
import ConfigService from "../../services/config"
import { compose } from "redux"
import { Link, withRouter } from "react-router-dom"
import { AppActions } from "../../store/app/actions"
import Alerts from "../Alerts"
import querystring from "querystring"

function debounce(fn, ms) {
	let timer
	return (_) => {
		clearTimeout(timer)
		timer = setTimeout(function (_) {
			timer = null
			fn.apply(this, arguments)
		}, ms)
	}
}

const Header = (props) => {
	const config = ConfigService?.config
	const { isLoggedIn, profile, headerContent, location, history, user } = props
	const [isMobile, setIsMobileView] = useState(window.innerWidth < 768)

	const {
		appSettings: { localizedText },
	} = useAppContext()

	React.useEffect(() => {
		const debouncedHandleResize = debounce(function handleResize() {
			setIsMobileView(window.innerWidth < 768)
		}, 100)

		window.addEventListener("resize", debouncedHandleResize)

		return () => {
			window.removeEventListener("resize", debouncedHandleResize)
		}
	}, [])

	const createNavLink = ({ label, href, className, children, ...rest }) => (
		<Link to={href} className={`${className} capitalize`} {...rest}>
			{children}
		</Link>
	)

	const getNavLinks = (links) => {
		return links.map((link) => {
			const url = link.href.replace(/([/.?*+^$[\]\\(){}|-])/g, "\\$1")
			const regExp = new RegExp(`${url}`, "i")

			return {
				...link,
				...(link.navMenuItemComponent ? { navMenuItemComponentProps: { label: link.label, href: link.href } } : {}),
				active: regExp.test(location.pathname),
			}
		})
	}

	const handleLogout = () => {
		props.logout()
	}

	const clickHandler = {
		[HeaderID.logout]: handleLogout,
	}

	const getHeaderSettings = () => {
		const navMenu = headerContent
			.find((item) => item.menuText === "navMenu")
			.children.filter((item) => (isMobile ? true : !item.isMobile))
			.map((item, i) => {
				const isMyAccountLink = item.type === ExternalLinkType.MY_ACCOUNT
				return {
					id: `${i}`,
					label: localizedText(item.menuText),
					href: getUrlByType(item.type, item.href, config.external),
					...(isMyAccountLink ? { navMenuItemComponent: (props) => createNavLink(props) } : {}),
					onClick: clickHandler[item.id],
				}
			})

		const userMenu = headerContent
			.find((item) => item.menuText === "userMenu")
			.children.filter((item) => (item.id === "contactInfo" ? user.hasCompleteProfile() : true))
			.map((item) => ({
				label: item.menuText,
				onClick() {
					if (item.id === "contactInfo") {
						history.push(getUrlByType(item.type, item.href, config.external))
					} else if (item.id === "signOut") {
						props.logout()
					}
				},
			}))

		const ctaButtons = [
			{
				label: "Create Account",
				variant: "outlined",
				size: "small",
				href: `/login`,
				placementOnMobile: "main-header",
				hideWhenSignedIn: true,
				onClick: (event) => {
					event.preventDefault()
					setLoginRedirect()
					const params = querystring.stringify({ redirect: getLoginRedirect() })
					props.history.push(`/login?${params}`)
				},
			},
			{
				label: "Login",
				variant: "ghost",
				size: "small",
				className: "underline",
				href: `/login`,
				placementOnMobile: "main-header",
				hideWhenSignedIn: true,
				onClick: (event) => {
					event.preventDefault()
					setLoginRedirect()
					const params = querystring.stringify({ redirect: getLoginRedirect() })
					props.history.push(`/login?${params}`)
				},
			},
		]

		if (props.isLoggedIn) {
			const requestServiceButton = {
				label: localizedText("REQUEST_SERVICE_BUTTON"),
				size: "medium",
				href: "/request-service",
				placementOnMobile: "main-header",
				disabled: !props.canRequestService,
				onClick: (event) => {
					event.preventDefault()
					if (props.canRequestService) {
						props.history.push("/request-service")
					}
				},
			}

			if (!props.canRequestService) {
				requestServiceButton.className = "cursor-not-allowed"
			}
			ctaButtons.push(requestServiceButton)
		}

		return {
			ctaButtons,
			utilityHeaderLinks: [],
			utilityHeaderIconLinks: [],
			signInLinkOptions: null,
			userMenuProps: {
				firstName: profile?.firstName,
				lastName: profile?.lastName,
				menuItems: userMenu,
			},
			navMenuItems: getNavLinks(navMenu),
		}
	}

	const variant = "light"
	const settings = headerContent ? getHeaderSettings() : null
	const [navMenuItems, setNavMenuItems] = useState()
	const handleNavMenuItemActivate = (activatedId) => {
		const updatedNavMenuItems = settings.navMenuItems.map((item) => {
			return { ...item, active: item.id === activatedId }
		})
		setNavMenuItems(updatedNavMenuItems)
	}

	const getHeaderLogo = () => {
		if (isSiteAHS()) {
			return isDesktop() ? <Ahs2024LogoColor /> : <Ahs2024LogomarkColor />
		} else {
			//return isDesktop() ? <HsaFrontdoorAltLogoColor height={50} /> : <HsaLogoColor width={60} height={50} />
			return <HsaLogoColor />
		}
	}

	useEffect(() => {
		if (settings) {
			!navMenuItems && setNavMenuItems(settings.navMenuItems)
		}
	}, [settings])

	useEffect(() => {
		if (settings) {
			const headerSettings = getHeaderSettings()
			setNavMenuItems(headerSettings.navMenuItems)
		}
	}, [isMobile])

	return (
		settings && (
			<div id="page-header" className="fixed z-40 top-0 left-0 right-0 md:relative">
				<Alerts isAboveHeaderOnDesktop={true} />

				<div className="header-wrapper">
					<GlobalHeader
						variant={variant}
						logoRenderer={() => (
							<a className="flex-1 md:flex-none" href={"/"}>
								{getHeaderLogo()}
							</a>
						)}
						navMenuItems={isLoggedIn ? navMenuItems : []}
						onNavMenuItemActivate={handleNavMenuItemActivate}
						ctaButtons={settings.ctaButtons}
						utilityHeaderLinks={settings.utilityHeaderLinks}
						utilityHeaderIconLinks={settings.utilityHeaderIconLinks}
						utilityHeaderLinksAlignment="center"
						isUserSignedIn={isLoggedIn}
						userMenuProps={settings.userMenuProps}
						sticky={false}
						signInLinkOptions={settings.signInLinkOptions}
						constrainContentWidth={true}
					/>
				</div>
			</div>
		)
	)
}

const mapStateToProps = (state) => ({
	headerContent: state.zesty.header?.content,
	user: state.user,
	profile: state.user.profile,
	isLoggedIn: state.user.isLoggedIn,
	currentContract: state.contract.current,
	contractList: state.contract.list,
	contractsLoading: state.contract.loading,
	getContractNotifications: state.contract.getContractNotifications,
	getFullStreetAddress: state.contract.getFullStreetAddress,
	canRequestService: state.requestService.canRequestService,
})

const mapDispatchToProps = (dispatch) => ({
	logout: () => dispatch(UserActions.logout()),
	toggleMobileAddressMenuOpen: () => dispatch(AppActions.toggleMobileAddressMenuOpen()),
	toggleMobileNavMenuOpen: (e) => {
		// do not change the URL hash when clicking on the menu button
		e.preventDefault()
		return dispatch(AppActions.toggleMobileNavMenuOpen())
	},
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Header)
