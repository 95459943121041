import { ActionsBuilder } from "../utils"
import namespace from "./namespace"
import ContractService from "../../services/contract"
import PaymentService from "../../services/payment"
import RequestService from "../../services/requestService"
import { AlertActions } from "../alert/actions"
import { PaymentActions } from "../payment/actions"
import { setCustomerID } from "../../analytics/user.analytics"
import { getContractCustomerID, makeCancelable, setCurrentContractIdToCookie } from "../../utils"
import DispatchService from "../../services/dispatch"
import ZestyService from "../../services/zesty"
import { RequestServiceActions } from "../requestService/actions"
import { DEFAULT_DISPATCHES_PER_PAGE } from "../../utils"
import { ZestyActions } from "../zesty/actions"

const actionsBuilder = new ActionsBuilder(namespace)

export const fetchContractsStart = actionsBuilder.createAction("fetchContractsStart", (state) => {
	state[namespace].loading = true
})

export const fetchContractsEnd = actionsBuilder.createAction("fetchContractsEnd", (state, { contracts, currentContract }) => {
	if (contracts && contracts.length) {
		state[namespace].list = contracts
		state[namespace].current = currentContract
	}

	state[namespace].loading = false
})

export const fetchContractsError = actionsBuilder.createAction("fetchContractsError", (state) => {
	state[namespace].loading = false
	state[namespace].fetchError = true
})

export const addPropertiesStart = actionsBuilder.createAction("addPropertiesStart", (state) => {
	state[namespace].propertiesAreSaving = true
})

export const addPropertiesEnd = actionsBuilder.createAction("addPropertiesEnd", (state) => {
	state[namespace].propertiesAreSaving = false
})

export const setCurrentContract = actionsBuilder.createAction("setCurrentContract", (state, contractID) => {
	const contract = state[namespace].list.find((contract) => contract.contractID === contractID)

	state[namespace].current = contract
})

export const removeContract = actionsBuilder.createAction("removeContract", (state, contractID) => {
	state[namespace].list = state[namespace].list.filter((contract) => contract.contractID !== contractID)

	// Shouldn't happen, but technically could happen
	if (state[namespace].current && state[namespace].current.contractID === contractID) {
		state[namespace].current = state[namespace].list[0]
	}
})

export const addExpirationInfoStart = actionsBuilder.createAction("addExpirationInfoStart", (state, { loadingPromise, contractID }) => {
	const contracts = state[namespace].list.slice()

	contracts.forEach((contract) => {
		if (contract.contractID === contractID) {
			contract.expirationInfoLoadingPromise?.cancel()
			contract.expirationInfoLoadingPromise = loadingPromise
			contract.expirationInfoLoading = true
		}
	})

	state[namespace].list = contracts
})

export const addExpirationInfoEnd = actionsBuilder.createAction("addExpirationInfoEnd", (state, { expirationInfo, contractID }) => {
	const contracts = state[namespace].list.slice()

	contracts.forEach((contract) => {
		if (contract.contractID === contractID) {
			contract.expirationInfoLoadingPromise?.cancel()
			contract.expirationInfoLoadingPromise = null
			contract.expirationInfoLoading = false
			contract.expirationInfo = expirationInfo
		}
	})

	state[namespace].list = contracts
})

export const addPaymentInfoStart = actionsBuilder.createAction("addPaymentInfoStart", (state, { loadingPromise, contractID }) => {
	const contracts = state[namespace].list.slice()

	contracts.forEach((contract) => {
		if (contract.contractID === contractID) {
			contract.paymentInfoLoadingPromise?.cancel()
			contract.paymentInfoLoadingPromise = loadingPromise
			contract.paymentInfo = null

			contract.paymentInfoError = false
		}
	})

	state[namespace].list = contracts
})

export const addPaymentInfoEnd = actionsBuilder.createAction("addPaymentInfoEnd", (state, { paymentInfo, contractID }) => {
	const contracts = state[namespace].list.slice()

	contracts.forEach((contract) => {
		if (contract.contractID === contractID) {
			contract.paymentInfoLoadingPromise?.cancel()
			contract.paymentInfoLoadingPromise = null
			if (paymentInfo) {
				contract.paymentInfo = paymentInfo
			}

			contract.paymentInfoError = !paymentInfo
		}
	})

	state[namespace].list = contracts
})

export const addUpgradeInfoStart = actionsBuilder.createAction("addUpgradeInfoStart", (state, { loadingPromise, contractID }) => {
	const contracts = state[namespace].list.slice()

	contracts.forEach((contract) => {
		if (contract.contractID === contractID) {
			contract.upgradeInfoLoadingPromise?.cancel()
			contract.upgradeInfoLoadingPromise = loadingPromise
		}
	})

	state[namespace].list = contracts
})

export const addUpgradeInfoEnd = actionsBuilder.createAction("addUpgradeInfoEnd", (state, { upgradeInfo, contractID }) => {
	const contracts = state[namespace].list.slice()

	contracts.forEach((contract) => {
		if (contract.contractID === contractID) {
			contract.upgradeInfoLoadingPromise?.cancel()
			contract.upgradeInfoLoadingPromise = null
			contract.upgradeInfo = upgradeInfo
		}
	})

	state[namespace].list = contracts
})

export const getExpirationInfo = (contract) => async (dispatch) => {
	try {
		const loadingPromise = makeCancelable(ContractService.getExpirationInfo(contract.contractID))
		dispatch(addExpirationInfoStart({ loadingPromise, contractID: contract.contractID }))

		const expirationInfo = await loadingPromise.promise
		dispatch(addExpirationInfoEnd({ expirationInfo, contractID: contract.contractID }))
	} catch (error) {
		if (!error.canceled) {
			console.error(error)
			dispatch(addExpirationInfoEnd({ expirationInfo: null, contractID: contract.contractID }))
		}
	}
}

export const getPaymentInfo = (contract) => async (dispatch) => {
	try {
		const loadingPromise = makeCancelable(PaymentService.fetchPaymentInfo(contract.contractID))
		dispatch(addPaymentInfoStart({ loadingPromise, contractID: contract.contractID }))

		const paymentInfo = await loadingPromise.promise
		dispatch(addPaymentInfoEnd({ paymentInfo, contractID: contract.contractID }))
	} catch (error) {
		if (!error.canceled) {
			console.error(error)
			dispatch(addPaymentInfoEnd({ paymentInfo: null, contractID: contract.contractID }))
		}
	}
}

export const getUpgradeInfo = (contract) => async (dispatch) => {
	try {
		const loadingPromise = makeCancelable(ContractService.getUpgradeInfo(contract.contractID))
		dispatch(addUpgradeInfoStart({ loadingPromise, contractID: contract.contractID }))

		const upgradeInfo = await loadingPromise.promise
		dispatch(addUpgradeInfoEnd({ upgradeInfo, contractID: contract.contractID }))
	} catch (error) {
		if (!error.canceled) {
			console.error(error)
			dispatch(addUpgradeInfoEnd({ upgradeInfo: null, contractID: contract.contractID }))
		}
	}
}

export const fetchDispatchDetailsByContractStart = actionsBuilder.createAction(
	"fetchDispatchDetailsByContractStart",
	(state, loadingPromise) => {
		state[namespace].dispatchDetailsByContractLoading = true
		state[namespace].dispatchDetailsByContractLoadingPromise = loadingPromise

		state[namespace].dispatchDetailsByContractError = false
	}
)

export const fetchDispatchDetailsByContractEnd = actionsBuilder.createAction(
	"fetchDispatchDetailsByContractEnd",
	(state, dispatchDetailsByContract) => {
		if (dispatchDetailsByContract) {
			state[namespace].dispatchDetailsByContract = dispatchDetailsByContract
			state[namespace].activePageDispatches = dispatchDetailsByContract.serviceRequests
		}

		state[namespace].dispatchDetailsByContractError = !dispatchDetailsByContract

		state[namespace].dispatchDetailsByContractLoading = false
		state[namespace].dispatchDetailsByContractLoadingPromise = null
	}
)

export const fetchOffsetDispatchDetailsByContractStart = actionsBuilder.createAction(
	"fetchOffsetDispatchDetailsByContractStart",
	(state, loadingPromise) => {
		state[namespace].offsetDispatchDetailsByContractLoading = true
		state[namespace].offsetDispatchDetailsByContractLoadingPromise = loadingPromise

		state[namespace].offsetDispatchDetailsByContractError = false
	}
)

export const fetchOffsetDispatchDetailsByContractEnd = actionsBuilder.createAction(
	"fetchOffsetDispatchDetailsByContractEnd",
	(state, offsetDispatchDetailsByContract) => {
		if (offsetDispatchDetailsByContract && offsetDispatchDetailsByContract.length > 0) {
			state[namespace].dispatchDetailsByContract.serviceRequests = offsetDispatchDetailsByContract.reduce(
				(out, { serviceRequests }) => out.concat(serviceRequests),
				[]
			)
		}

		state[namespace].offsetDispatchDetailsByContractError = !offsetDispatchDetailsByContract

		state[namespace].offsetDispatchDetailsByContractLoading = false
		state[namespace].offsetDispatchDetailsByContractLoadingPromise = null
	}
)

export const setActiveDispatchPage = actionsBuilder.createAction("setActiveDispatchPage", (state, activePage) => {
	const startIndex = (activePage - 1) * DEFAULT_DISPATCHES_PER_PAGE
	const endIndex = startIndex + DEFAULT_DISPATCHES_PER_PAGE
	state[namespace].activePageDispatches = state[namespace].dispatchDetailsByContract.serviceRequests.slice(startIndex, endIndex)
	state[namespace].dispatchDetailsByContract.pagination.activePage = activePage
})

export const setServiceItemsStart = actionsBuilder.createAction("setServiceItemsStart", (state, serviceItemsLoadingPromise) => {
	state[namespace].serviceItems = []
	state[namespace].serviceItemsLoading = true
	state[namespace].serviceItemsLoadingPromise = serviceItemsLoadingPromise
})

export const setServiceItemsEnd = actionsBuilder.createAction(
	"setServiceItemsEnd",
	(state, { serviceItems, serviceItemCategories, limitationsAndExclusions }) => {
		state[namespace].serviceItems = serviceItems
		state[namespace].serviceItemsError = !serviceItems
		state[namespace].serviceItemCategories = serviceItemCategories
		state[namespace].limitationsAndExclusions = limitationsAndExclusions
		state[namespace].serviceItemsLoading = false
		state[namespace].serviceItemsLoadingPromise = null
	}
)

export const setDownloadingContract = actionsBuilder.createAction("setDownloadingContract", (state, { loading }) => {
	state[namespace].isDownloadingContract = loading
})

export const ContractActions = {
	fetchContracts:
		(currentContractID = undefined) =>
		async (dispatch, getState) => {
			dispatch(fetchContractsStart())

			const state = getState()

			let contracts = []
			let currentContract = null

			if (state.user.profile.contractCustomer.length > 0) {
				const contractIDs = state.user.profile.contractCustomer.map((contract) => {
					return contract.contractID
				})

				try {
					contracts = await ContractService.getMultipleContractInformation(contractIDs)
				} catch (e) {
					console.log("Failed to fetch contracts")

					dispatch(fetchContractsError())
					return false
				}

				if (!currentContractID) {
					currentContractID = window.sessionStorage.getItem("RequestServiceSetContract")

					if (currentContractID) {
						window.sessionStorage.removeItem("RequestServiceSetContract")
					} else {
						currentContractID = window.sessionStorage.getItem("currentContractID")
					}
				}

				currentContract = contracts.find((contract) => contract.contractID === currentContractID) || contracts[0]

				// Set the current contract to be remembered
				window.sessionStorage.setItem("currentContractID", currentContract.contractID)
				//Set cookie for renewal flow
				setCurrentContractIdToCookie(currentContract.contractID)
			}

			dispatch(fetchContractsEnd({ contracts, currentContract }))

			if (currentContract) {
				setCustomerID(getContractCustomerID(state.user.profile.contractCustomer, currentContract.contractID))

				await Promise.all([
					dispatch(getExpirationInfo(currentContract)),
					dispatch(getPaymentInfo(currentContract)),
					dispatch(getUpgradeInfo(currentContract)),
				])
			}

			// Note: If we need to wait for all contract datas to be loaded, await this Promise.all()
			Promise.all(
				contracts.reduce((promises, contract) => {
					if (contract !== currentContract) {
						promises.push(dispatch(getExpirationInfo(contract)))
						promises.push(dispatch(getPaymentInfo(contract)))
						promises.push(dispatch(getUpgradeInfo(contract)))
					}

					return promises
				}, [])
			)

			dispatch(PaymentActions.setPaymentInfoFromCurrentContract())
			dispatch(RequestServiceActions.canRequestService())
			return true
		},

	setCurrentContract: (contractID) => async (dispatch, getState) => {
		const state = getState()

		// Set the current contract to be remembered
		window.sessionStorage.setItem("currentContractID", contractID)
		//Set cookie for renewal flow
		setCurrentContractIdToCookie(contractID)

		if (state.user.isLoggedIn) {
			const newContract = state.contract.list.find((contract) => contract.contractID === contractID)
			if (newContract) {
				dispatch(setCurrentContract(contractID))
				setCustomerID(getContractCustomerID(state.user.profile.contractCustomer, contractID))
				dispatch(RequestServiceActions.canRequestService())

				if (newContract.paymentInfo) {
					dispatch(PaymentActions.setPaymentInfoFromCurrentContract())
				} else {
					dispatch(PaymentActions.fetchPaymentInfo())
				}
			}
		}
	},

	addProperties: (contracts) => async (dispatch) => {
		dispatch(addPropertiesStart())

		let response = null
		try {
			response = await ContractService.addProperties(contracts)
		} catch (e) {
			response = e.response
		}

		dispatch(addPropertiesEnd())

		return response
	},

	removeContract: (contractID) => async (dispatch, getState) => {
		const state = getState()
		const contract = state[namespace].list.find((contract) => contract.contractID === contractID)
		const id = `remove_contract_${contract.contractID}`

		// remove existing alert in case it exists
		dispatch(AlertActions.removeAlert(id))

		try {
			await ContractService.removeContract(contract.contractID)

			dispatch(removeContract(contract.contractID))
			dispatch(
				AlertActions.createAlert({
					useBDS: true,
					type: "success",
					message: "ADDRESS_BAR.REMOVE_ADDRESS_SUCCESS",
					messageData: {
						streetAddress: contract.property.streetAddress,
					},
					closeButton: true,
				})
			)
		} catch (e) {
			const options = {
				useBDS: true,
				type: "error",
				message: "ADDRESS_BAR.REMOVE_ADDRESS_ERROR",
				button: {
					text: "ADDRESS_BAR.REMOVE_ADDRESS_TRY_AGAIN",
					link: "#",
					onClick: (e) => {
						e.preventDefault()
						dispatch(ContractActions.removeContract(contractID))
					},
				},
				messageData: { streetAddress: contract.property.streetAddress },
			}

			dispatch(AlertActions.createAlert({ id, options }))
		}
	},

	getExpirationInfo: () => async (dispatch, getState) => {
		const state = getState()

		await dispatch(getExpirationInfo(state[namespace].current))
	},

	fetchDispatchDetailsByContract: (itemsPerPage) => async (dispatch, getState) => {
		const state = getState()
		const { current } = state[namespace]
		const { dispatchDetailsByContractLoadingPromise, offsetDispatchDetailsByContractLoadingPromise } = state[namespace]

		if (dispatchDetailsByContractLoadingPromise) {
			dispatchDetailsByContractLoadingPromise.cancel()
		}
		if (offsetDispatchDetailsByContractLoadingPromise) {
			offsetDispatchDetailsByContractLoadingPromise.cancel()
		}

		if (!current) {
			return
		}

		const cancelablePromise = makeCancelable(DispatchService.fetchDispatchDetailsByContract(current.contractID, itemsPerPage))
		dispatch(fetchDispatchDetailsByContractStart(cancelablePromise))

		try {
			const dispatchDetailsByContract = await cancelablePromise.promise

			dispatch(fetchDispatchDetailsByContractEnd(dispatchDetailsByContract))
			if (dispatchDetailsByContract?.pagination?.totalRecords > itemsPerPage) {
				dispatch(
					ContractActions.fetchOffsetDispatchDetailsByContract(dispatchDetailsByContract?.pagination?.totalRecords, itemsPerPage)
				)
			}
		} catch (e) {
			if (!e.canceled) {
				dispatch(fetchDispatchDetailsByContractEnd())
			}
		}
	},

	fetchOffsetDispatchDetailsByContract: (totalRecords, itemsPerPage) => async (dispatch, getState) => {
		const state = getState()
		const { current } = state[namespace]
		const { offsetDispatchDetailsByContractLoadingPromise } = state[namespace]

		if (offsetDispatchDetailsByContractLoadingPromise) {
			offsetDispatchDetailsByContractLoadingPromise.cancel()
		}

		if (!current) {
			return
		}

		const allDispatchesItemsPerPage = 25

		const promises = []
		for (let i = 0; i * allDispatchesItemsPerPage < totalRecords; i++) {
			promises.push(
				DispatchService.fetchDispatchDetailsByContract(current.contractID, allDispatchesItemsPerPage, i * allDispatchesItemsPerPage)
			)
		}

		const cancelablePromise = makeCancelable(Promise.all(promises))
		dispatch(fetchOffsetDispatchDetailsByContractStart(cancelablePromise))

		try {
			const offsetDispatchDetailsByContract = await cancelablePromise.promise

			dispatch(fetchOffsetDispatchDetailsByContractEnd(offsetDispatchDetailsByContract))
		} catch (e) {
			if (!e.canceled) {
				dispatch(fetchOffsetDispatchDetailsByContractEnd())
			}
		}
	},

	setActiveDispatchPage: (activePage) => (dispatch) => {
		dispatch(setActiveDispatchPage(activePage))
	},

	fetchServiceItems: () => async (dispatch, getState) => {
		const state = getState()
		const { current } = state[namespace]
		const { serviceItemsLoadingPromise } = state[namespace]

		if (serviceItemsLoadingPromise) {
			serviceItemsLoadingPromise.cancel()
		}

		if (!current) {
			return
		}

		dispatch(ZestyActions.getWhatsCoveredPopupConfigs())

		const cancelablePromise = makeCancelable(
			(async () => {
				const serviceItemCategories = await RequestService.fetchServiceItems(current.contractID, true)

				const serviceItems = []

				serviceItemCategories.forEach((category) => {
					serviceItems.push(...category.serviceItems)

					category.subCategories.forEach((subCategory) => {
						serviceItems.push(...subCategory.serviceItems)
					})
				})

				const limitationsAndExclusions = await ZestyService.getPlanLimitationsAndExclusions()

				return { serviceItems, serviceItemCategories, limitationsAndExclusions }
			})()
		)
		dispatch(setServiceItemsStart(cancelablePromise))

		try {
			const { serviceItems, serviceItemCategories, limitationsAndExclusions } = await cancelablePromise.promise

			dispatch(setServiceItemsEnd({ serviceItems, serviceItemCategories, limitationsAndExclusions }))
		} catch (e) {
			console.error(e)
			if (!e.canceled) {
				dispatch(setServiceItemsEnd({}))
			}
		}
	},

	downloadContract: (contractID) => async (dispatch) => {
		dispatch(setDownloadingContract({ loading: true }))
		return ContractService.getContractDocument(contractID)
			.then((response) => {
				window.open(response.DownloadLink, "_blank")
			})
			.finally(() => dispatch(setDownloadingContract({ loading: false })))
	},
}

export const actions = actionsBuilder.exportActions()
