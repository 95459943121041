import { OIDCService } from "@ftdr/pkce-js"
import ConfigService from "./config"
import axios from "axios"
import jwtDecode from "jwt-decode"
import { GetGuestToken } from "@ftdr/crypto-js"
import moment from "moment"
import { extend } from "../utils"

const GUEST_TOKEN_RETRY_ATTEMPTS = 3
const REFRESH_TOKEN_BEFORE_EXPIRE_IN_SEC = 60

class AuthService {
	_odicClient = null

	getClientConfig(additionalConfig) {
		const siteHostname = window.location.origin
		const config = ConfigService.config?.auth ?? {}

		const clientConfig = {
			authority: config.host,
			metadata_uri: `${config.host}/.well-known/openid-configuration/${config.tenant_id}`,
			client_id: config.client_id,
			redirect_uri: `${siteHostname}/login`,
			post_logout_redirect_uri: `${siteHostname}/logout`,
			scope: "openid email profile offline_access",
			debug: config.debug ?? false,
			extraQueryParams: {
				tenantId: config.tenant_id,
			},
			refreshTokens: true,
			refreshBeforeTokenExpIn: isNaN(`${config.refreshBeforeTokenExpIn}`)
				? REFRESH_TOKEN_BEFORE_EXPIRE_IN_SEC
				: Number(`${config.refreshBeforeTokenExpIn}`),
		}

		return extend(clientConfig, additionalConfig)
	}

	getClient(additionalConfig) {
		if (additionalConfig) {
			return new OIDCService(this.getClientConfig(additionalConfig))
		}

		if (!this._odicClient) {
			const config = this.getClientConfig()

			if (config.debug && !this._loggedConfig) {
				this._loggedConfig = true
				console.log("AuthConfig", JSON.stringify(config, null, "    "))
			}

			this._odicClient = new OIDCService(config)
		}

		return this._odicClient
	}

	getAxios() {
		return axios.create({
			baseURL: ConfigService.config?.auth?.host,
		})
	}

	async getUserInfo() {
		const user = await this.getClient().getUser()

		const response = await this.getAxios().get("/api/user", {
			headers: {
				Authorization: `${user.token_type} ${user.access_token}`,
			},
		})

		return response.data.user
	}

	gettingGuestToken = false
	guestTokenWaiters = []

	getGuestToken(attemptsRemaining = 0) {
		return new Promise((resolve, reject) => {
			if (this.gettingGuestToken) {
				this.guestTokenWaiters.push({ resolve, reject })
				return
			}

			this.gettingGuestToken = true

			this._getGuestToken(attemptsRemaining)
				.then((token) => {
					resolve(token)

					this.guestTokenWaiters.splice(0).forEach(({ resolve }) => resolve(token))
					this.gettingGuestToken = false
				})
				.catch((error) => {
					reject(error)

					this.guestTokenWaiters.splice(0).forEach(({ reject }) => reject(error))
					this.gettingGuestToken = false
				})
		})
	}

	_getGuestToken(attemptsRemaining = 0) {
		return new Promise((resolve, reject) => {
			GetGuestToken({
				baseUrl: ConfigService.config?.auth?.guestHost,
				handler: (token) => {
					if (token instanceof Error) {
						if (attemptsRemaining > 0) {
							this._getGuestToken(attemptsRemaining - 1)
								.then(resolve)
								.catch(reject)
						} else {
							reject(token)
						}
					} else {
						resolve(token)
					}
				},
			})
		})
	}

	async getGuestTokenWithSession() {
		let guestToken = window.sessionStorage.getItem("guestToken")

		if (guestToken && moment.unix(jwtDecode(guestToken).exp).isAfter(moment.now())) {
			return guestToken
		}

		guestToken = await this.getGuestToken(GUEST_TOKEN_RETRY_ATTEMPTS)

		window.sessionStorage.setItem("guestToken", guestToken)

		return guestToken
	}

	async getAccessToken() {
		let user = await this.getClient().getUser()
		if (user) {
			if (user.expired) {
				const refreshUser = await this.getClient().renewToken()
				user = refreshUser
			}
			return { token: user.access_token, tokenType: user.token_type, isGuestToken: false }
		}

		const guestToken = await this.getGuestTokenWithSession()

		return { token: guestToken, tokenType: "Bearer", isGuestToken: true }
	}
}

export default new AuthService()
